
  import { defineComponent, ref, computed, reactive, getCurrentInstance } from "vue"
  import TitleActions from "@/components/shared/TitleActions.vue"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { Posting } from "@/models/postings"
  import { Household } from "@/models/households"
  import { Suggestion } from "@/models/suggestions"
  import { CareStay } from "@/models/care_stays";
  import { resourceUrl, fullName } from "@/utils/dataExtractors"
  import { apiDataMorpher, extractRelationshipIds, extractIncludedTypes, fetchRelated, localIndex } from "@/utils/apiDataHelper"
  import { shortDate, stringSort } from "@/utils/helper"
  import { CollectionResourceDoc, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { provideRefreshData } from "@/utils/refreshData"
  import { visorColumns } from "@/view_scripts/table_columns/visor_columns"
  import BKFormEdit from "@/components/shared/BKFormEdit.vue"
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import SuggestionSelect from "@/components/shared/SuggestionSelect.vue"
  import CareStayTable from "@/components/internal/CareStayTable.vue"
  import Visors from "@/components/shared/Visors.vue"
  import BkSuggestions from "@/components/shared/BKSuggestions.vue"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import PostingFormEdit from "@/components/shared/PostingFormEdit.vue"
  import BkPresentMailEdit from "@/components/shared/BkPresentMailEdit.vue"
  import RepositoryFactory from '@/api/repositoryFactory'

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      TitleActions,
      BKFormEdit,
      TicketEdit,
      Visors,
      BkSuggestions,
      PostingFormEdit,
      SuggestionSelect,
      BkPresentMailEdit,
      CareStayTable
    },
    props: {
      id: {
        type: String,
        required: true
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const newSuggestionModal = ref()
      const postingEditModal = ref()
      const posting = computed<ResourceObject<string, Posting>>(() => {
        const element = root.$store.state.postings.element
        postingData.posting = element
        return element
      })
      const postingResponse = computed(() => root.$store.state.postings.elementResponse)
      const household = computed<ResourceObject<string, Household>>(() => ((postingResponse.value?.included?.filter( obj => obj.type==='households')||[]) as ResourceObject<string, Household>[])[0])
      const newTicketModal = ref()
      // const bkSelectModal = ref()
      const mailEditModal = ref()
      const addVisorModal = ref()
      const mailData = reactive({mail: {}, suggestions: []})
      const visorIds = computed<string[]>(() => {
        if (postingResponse.value) {
          const ids = extractRelationshipIds(postingResponse.value, 'visors')
          getVisors(ids)
          getCareStays(ids).then(processCareStayResponse)
          return ids
        } else {
          return []
        }
      })
      // const suggestionIds = ref<string[]>([])
      const agencyIds = ref<string[]>([])
      const careStays = ref<ResourceObject<string, CareStay>[]>([])
      const showLoading = ref<Boolean>(false)
      const postingData = reactive({
        agencies: [],
        available_nationalities: [],
        posting: {}
      })
      const newVisorData = reactive({
        options: [{}],
        selected: []
      })

      const refreshData = async () => {
        const postingQuery = { include: 'household' }
        root.$store.dispatch('postings/get', { id: props.id, queryParams: postingQuery })
      }

      const getCareStays = async (visorIds: string[]) => {
        const query = {
          'filter[visor_id]': visorIds.join(','),
          'include': 'contract,careGiverInstance.careGiver,visor.agency',
          'fields[file_meta_datas]': 'contentType,filename,length',
          'fields[contracts]': 'archived',
          'fields[care_givers]': 'firstName,lastName',
          'fields[agencies]': 'name',
          'page[size]': 100
        }
        return root.$store.dispatch('care_stays/load2', query)
      }

      const processCareStayResponse = (resp:any) => {
        const idx = localIndex(resp)
        const tableItems = resp.data.reduce((res: any[], cs: any) => {
          const cg = fetchRelated(cs, 'careGiverInstance.careGiver', idx)
          const agency = fetchRelated(cs, 'visor.agency', idx)
          cs.meta = {
            cGName: fullName(cg),
            cgiId: cs.relationships?.careGiverInstance?.data?.id,
            contractId: cs.relationships?.contract?.data?.id,
            agencyName: agency?.attributes?.name
          }
          res.push(cs)
          return res
        },[])
        careStays.value = stringSort(tableItems, (s) => s.attributes.billStart, false)
      }

      const getVisors = async (visorIds: string[]) => {
        const params = {
          'filter[id]': visorIds.join(','),
          'page[size]': visorIds.length + 5
        }
        const visorsRepository = RepositoryFactory.get("visors")
        const locVisResp = await visorsRepository.list(params)//await root.$store.dispatch('visors/load', params)
        agencyIds.value = extractRelationshipIds(locVisResp, 'agency')
        // suggestionIds.value = extractRelationshipIds(locVisResp, 'suggestions')
      }

      provideRefreshData(refreshData)
      refreshData()

      const togglePostingActiveButton = () => {
        if (posting.value && posting.value.attributes?.active) {
          return {
            title: 'Pausieren',
            tooltip: 'Stellenauschreibung pausieren (Agenturen können keine Vorschläge mehr senden)',
            onClick: () => {postingUpdate({usecase: 'deactivate'})}
          }
        } else {
          return {
            title: 'Aktivieren',
            tooltip: 'Stellenauschreibung aktivieren (Agenturen können wieder Vorschläge senden)',
            onClick: () => {postingUpdate({usecase: 'activate'})}
          }
        }
      }

      const titleActions = computed(() => [
        {
          title: 'Bearbeiten',
          icon: 'pencil-alt',
          tooltip: 'Stellenauschreibung bearbeiten',
          onClick: () => postingEditModal.value.show()
        },
        // {
        //   categoryTitle: 'Vorschlagen',
        //   icon: ['fas','user-nurse'],
        //   tooltip: 'Neue BK eingeben',
        //   onClick: () => newSuggestionModal.value.show()
        // },
        newTicketAction(newTicketModal),
        {
          title: 'Aktionen',
          tooltip: 'Kontext Aktionen',
          children: [
            {
              title: 'Agenturen benachrichtigen',
              tooltip: 'Agenturen über Änderungen an der Stellenauschreibung benachrichtigen.',
              onClick: () => root.$store.dispatch('postings/edit', { id: props.id, body: { usecase: 'agency_notify' } })

            },
            // {
            //   title: 'BK(s) vorschlagen',
            //   tooltip: 'BK Vorschläge an den Kunden versenden',
            //   onClick: () => bkSelectModal.value.show()
            // },
            togglePostingActiveButton(),
            {
              title: 'Agentur hinzufügen',
              tooltip: 'Stellenauschreibung weiteren Agenturen zeigen',
              onClick: async () => {
                showLoading.value = true
                const query = {
                  'filter[active]': true,
                  'page[size]': 100,
                  'page[number]': 1
                }
                await root.$store.dispatch('agencies/load', query)
                const unsortedAgencies = root.$store.state.agencies.data.reduce((res, ag) => {
                  if (ag.id && !agencyIds.value.includes(ag.id)) {
                    res.push({ value: ag.id, label: ag.attributes?.name })
                  }
                  return res
                },[] as any)
                newVisorData.options = stringSort(unsortedAgencies, (a)=>a.label)
                showLoading.value = false
                addVisorModal.value.show()
              }
            },
            // {
            //   title: posting.value.attributes.active ? 'Aktivieren' : 'Deaktivieren',
            //   tooltip: 'Stellenauschreibung aktivieren (Agenturen können wieder Vorschläge senden)',
            //   onClick: () => {alert('do something to activate the posting')}
            // }
          ]
        },
      ])

      const onPostingUpdate = () => {
        postingEditModal.value.hide()
        refreshData()
      }

      const postingUpdate = async (data:any) => {
        showLoading.value = true
        try {
          await root.$store.dispatch('postings/edit', { id: props.id, body: data })
          refreshData()
          root.$emit('bv::refresh::table', 'postingVisorTable')
        } finally {
          showLoading.value = false
        }
      }

      const getBkMailInfo = async (suggestionIds: string[]) => {
        showLoading.value = true
        await root.$store.dispatch('suggestions/get_presentation_mail_data', suggestionIds)
        mailData.mail = root.$store.state.suggestions.presentationMailData.data.mail
        mailData.suggestions = root.$store.state.suggestions.presentationMailData.data.suggestions
        // bkSelectModal.value.hide()
        mailEditModal.value.show()
        showLoading.value = false
      }

      const sendMailData = async (mailData: any) => {
        try {
          await root.$store.dispatch('postings/deliver_bk_presentation_mail_data', { id: props.id, data: mailData })
          mailEditModal.value.hide()
          refreshData()
        } catch(e) {
          console.log('bkSuggestionMail error: ', e)
        }
      }

      const submitNewAgencyIds = async () => {
        showLoading.value = true
        const submitData = {usecase: 'add_visors', agency_ids: newVisorData.selected}
        await root.$store.dispatch('postings/edit', { id: props.id, body: submitData })
        showLoading.value = false
        refreshData()
      }

      const submitTicket = () => {
        refreshData()
        newTicketModal.value.hide()
      }

      const actionVisorColumns:any = [...visorColumns, {
        component: () => import("@/components/shared/table/cells/ButtonsCell.vue"),
        key: "actions",
        title: "Aktionen",
        sortable: false,
        options: {
          buttons: [
            (data: any) => {
              const act = data.item.attributes.lastActivated != null
              const confirmActivateMsg = "Die Agentur kann wieder Vorschläge für diese Stelle senden?"
              const confirmDeactivateMsg = "Die Agentur kann vorrübergehend keine Vorschläge mehr für diese Stelle senden?"
              const confirmMsg = act ? confirmDeactivateMsg : confirmActivateMsg
              return {
                title: (act ? 'Zugang pausieren' : 'Zugang öffnen'),
                icon: (act ? ['fas', 'pause'] : ['fas', 'play']),
                action: async (data:any) => {
                  const rpc = act ? 'deactivate' : 'activate'
                  // console.log('Visor toggled: ', data.item.id, act)
                  if (await confirm(root, confirmMsg)) {
                    showLoading.value = true
                    try {
                      await root.$store.dispatch('visors/edit', { id: data.item.id, body: { usecase: rpc } })
                      root.$emit('bv::refresh::table', 'postingVisorTable')
                    } finally {
                      showLoading.value = false
                    }
                  }
                }
              }
            },
            {
              title: 'Agentur Blocken',
              icon: ['far', 'ban'],
              action: async (data:any) => {
                if (data.item.attributes.disabled) {
                  prompt(root, 'Dieser Agenturzugang ist bereits geblockt.')
                } else {
                  const confirmed = await confirm(root, 'Agenturzugang blocken?')
                  if (confirmed) {
                    await root.$store.dispatch('visors/edit', { id: data.item.id, body: { usecase: 'disable' } })
                    refreshData()
                  }
                }
              }
            },
          ]
        }
      }]

      return {
        posting,
        household,
        titleActions,
        newSuggestionModal,
        newTicketModal,
        submitTicket,
        postingEditModal,
        // bkSelectModal,
        mailEditModal,
        addVisorModal,
        visorIds,
        newVisorData,
        submitNewAgencyIds,
        actionVisorColumns,
        resourceUrl,
        onPostingUpdate,
        postingData,
        getBkMailInfo,
        showLoading,
        mailData,
        sendMailData,
        shortDate,
        careStays
      }
    }
  })
