import { resourceUrl } from '@/utils/dataExtractors'
import { secsToHrsnMins } from '@/utils/helper'
export const visorColumns = [
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "attributes.agency.attributes.name",
    title: "Agentur",
    sortable: true,
    options: {
      hrefFunction: (data: any) => resourceUrl(data.item)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.createdAt",
    title: "Erstellt",
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.lastActivated",
    title: "Zuletzt aktiviert",
    sortable: true,
    options: { type: 'datetime' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.activeTime",
    title: "war aktiv",
    sortable: true,
    options: {contentFunction: (data: any) => {
      const actTime = data.item.attributes.activeTime
      return actTime ? secsToHrsnMins(actTime) : 0
    }}
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.care_stays",
    title: "Vorschläge",
    sortable: true,
    options: {
      contentFunction: (data: any) => data.item.attributes.care_stays.length
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.disabled",
    title: "Ausgeschaltet",
    options: {
      colorInverted: true
      // contentFunction: (data: any) => data.item.attributes.suggestions.length
    }
  },
]