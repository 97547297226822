
  import { defineComponent, ref, computed, getCurrentInstance, onMounted } from "vue"
  import {fullName} from '@/utils/dataExtractors'
  import {stringSort, shortDate, ageFromDate} from '@/utils/helper'
  import HtmlElement from "@/components/shared/form/elements/html/HtmlElement.vue"
  import { confirm } from '@/utils/interactionModals'

  interface Props {
    [key:string]: any
  }

  export default defineComponent({
    components: {
      HtmlElement,
    },
    props: {
      mailData: {
        type: Object,
        required: true
      },
    },
    setup(props: Props, { emit } ) {
      const root = getCurrentInstance().proxy
      const gerSkills = {
        '1': "Sehr gut - Sprechen und Verstehen fließend",
        '2': "Gut - Einfache Gespräche - Telefonat möglich",
        '3': "Befriedigend - Einfache Sätze und Wörter",
        '4': "Ausreichend - Spricht einige Worte",
        '5': "Kaum Deutsch - Versteht einzelne Worte",
        '6': "Kein Deutsch",
      }

      const cancelAction = async () => {
        const confirmation = await confirm(root, 'Alle Eingaben werden verworfen...')
        if (confirmation) emit('cancel')
      }

      const totals = ref({})

      const updateTotals = () => {
        const reassigner = {}
        props.mailData.careStays.forEach((entry) => {
          const cs = entry.careStay
          const seg = entry.segment
          const mSum = -1 * parseFloat(cs.discountSeller)
          const baseSum = parseFloat(cs.price) + parseFloat(seg.provPfs) + parseFloat(seg.provSeller)
          const optSum = (cs.nightCare||0) + (cs.driversLicense||0) + (cs.dubCr||0) + (cs.dubHh||0)
          reassigner[cs.id] = mSum + baseSum + optSum
        })
        totals.value = reassigner
      }

      onMounted(updateTotals)

      const okAction = () => { emit('ok', props.mailData) }

      return {
        shortDate,
        gerSkills,
        cancelAction,
        okAction,
        ageFromDate,
        updateTotals,
        totals
      }
    }
  })
