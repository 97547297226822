
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { Suggestion } from "@/models/suggestions";
  import { suggestionColumns } from "@/view_scripts/table_columns/suggestion_columns";
  import { apiDataMorpher, extractRelationshipIds, localIndex } from "@/utils/apiDataHelper"
  import {fullName, fileSize} from '@/utils/dataExtractors'
  import {stringSort} from '@/utils/helper'

  interface Props {
    suggestionIds: string[],
    isSwap: Boolean
  }

  export default defineComponent({
    props: {
      suggestionIds: {
        type: Array,
        default: () => []
      },
      isSwap: {
        type: Boolean,
        default: false
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const suggestionIdsString = computed(() => props.suggestionIds.join())
      const items = ref<any[]>([])
      const fields = [{key:'selected', label:'X'},'Status', 'BK', 'Agentur', {key: 'fmds', label:'Anh.'}]
      const selected = ref([])
      const fileSizes = ref(0)
      const getItems = async() => {
        if (props.suggestionIds.length === 0) return
        showLoading.value = true
        const suggestionQuery = {
          'filter[id]': suggestionIdsString.value,
          'page[number]': 1,
          'page[size]': props.suggestionIds.length,
          'include': 'segments,careGiverInstance.fileMetaDatas,careGiverInstance.careGiver,visor.agency',
          'fields[careGivers]': 'firstName,lastName',
          'fields[careStay]': 'stage',
          'fields[agencies]': 'name',
          'fields[fileMetaDatas]': 'length',
        }
        root.$store.dispatch('care_stays/load2', suggestionQuery)
          .then((r) => {
            const idx = localIndex(r)
            const cgiOptions = r.data.filter((sug) => ['Neu', 'Vorgestellt'].includes(sug.attributes.stage))
              .map((sug) => {
                const visorId = (sug as any).relationships?.visor?.data?.id
                const segmentId = (sug as any).relationships?.segment?.data?.id
                // const segment = idx[segmentId]
                const visor = idx[visorId]
                const agency = idx[visor.relationships.agency.data.id]
                const cgiId = sug.relationships.careGiverInstance.data.id
                const cgi = idx[cgiId]
                const fmds = cgi.relationships.fileMetaDatas.data.map((fmdRo) => idx[fmdRo.id])
                const cgId = cgi.relationships.careGiver.data.id
                const cg = idx[cgId]
                return {
                  id: sug.id,
                  Status: sug.attributes.stage || 'fehlt',
                  BK: fullName(cg),
                  Agentur: agency?.attributes?.name,
                  fmds: fmds.length,
                  sizes: fmds.map(f => f.attributes.length)
                }
              })
            items.value = stringSort(cgiOptions, (e) => e['Status'])
            showLoading.value = false
          })
      }

      getItems()

      const onRowSelected = (items: any) => {
        fileSizes.value = items.reduce((res, i) => res.concat(i.sizes),[])
          .reduce((a,b)=>a+b,0)
        selected.value = items.map(i=>i.id)
      }

      const cancelAction = () => emit('cancel')
      const okAction = () => emit('ok', selected.value)

      return {
        items,
        fields,
        selected,
        fileSizes,
        onRowSelected,
        cancelAction,
        okAction,
        showLoading,
        fileSize
      }
    }
  })
