
  import { defineComponent, ref, computed, getCurrentInstance } from "vue"
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { visorColumns } from "@/view_scripts/table_columns/visor_columns"

  interface Props {
    visorIds: string[],
    columns: any[],
    options: {[key: string]: any}
  }

  export default defineComponent({
    props: {
      visorIds: {
        type: Array,
        default: () => []
      },
      columns: {
        type: Array,
        default: () => visorColumns
      },
      options: {
        type: Object,
        default: () => {}
      }
    },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const totalItems = computed(() => props.visorIds.length)

      const visorIdsString = computed(() => props.visorIds.join())

      const getItems = async(ctx: BvTableCtxObject) => {
        if (props.visorIds.length === 0) return
        const visorQuery = {
          'filter[id]': visorIdsString.value,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
          include: 'agency,care_stays'
        }
        await root.$store.dispatch('visors/load', visorQuery)
        return root.$store.state.visors.data
      }

      const tableOptions = {title:'Angefragte Agenturen', stacked: 'md', ...props.options}

      return {
        getItems,
        totalItems,
        visorIdsString,
        tableOptions
      }
    }
  })
