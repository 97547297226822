import { render, staticRenderFns } from "./BkPresentMailEdit.vue?vue&type=template&id=6baf79e2&scoped=true&lang=pug"
import script from "./BkPresentMailEdit.vue?vue&type=script&lang=ts"
export * from "./BkPresentMailEdit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6baf79e2",
  null
  
)

export default component.exports